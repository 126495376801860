import {
  useGetPageQuery,
  isPopulatedReference,
  PageLayout,
  useGetLocalesQuery,
  SectionParser,
  HeroConnected,
} from '@front/master';
import { Section } from '@shared/master-types';
import React from 'react';

export type TSectionsPageProps = {
  id: string;
  locale: string;
  draft: boolean;
};

const SectionsPage: React.FC<TSectionsPageProps> = props => {
  const { draft, id, locale } = props;

  const { data: page } = useGetPageQuery({ id, draft, locale, depth: 3 });
  const { data: localesObject } = useGetLocalesQuery({ locale, draft });

  if (!page) {
    return null;
  }

  const locales = localesObject?.docs;
  const sections = (page?.parts?.sections?.defaultSections || []) as Section[];
  const hero = isPopulatedReference(page?.parts?.hero)
    ? page?.parts?.hero
    : undefined;
  const isShowBreadcrumbs = !page?.isBreadcrumbsHidden && !hero;

  return (
    <PageLayout
      locale={locale}
      draft={draft}
      meta={{
        ...page?.meta,
        url: page?.url,
      }}
      breadcrumbs={isShowBreadcrumbs ? page?.breadcrumbs : []}
    >
      {hero && <HeroConnected hero={hero} locales={locales} />}
      {sections?.map(section => (
        <SectionParser key={section.id} section={section} locales={locales} />
      ))}
    </PageLayout>
  );
};

export {
  getStaticPaths,
  getStaticProps,
} from '@front/master/components/pages/SectionsPage';

export default SectionsPage;
